import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BiotechIcon from '@mui/icons-material/Biotech';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';

const MenuList = () => {

  const navigate = useNavigate();

  const handleChangeView = (event) => {
    event.preventDefault();
    const path = event.currentTarget.getAttribute('name');
    if (path === "home") {
      navigate('/');
    } else {
      navigate(`/${path}`);
    }
  }

  return (
    <React.Fragment>
      <ListItemButton name="home" onClick={handleChangeView}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
      <ListItemButton name="SingleChipPage" onClick={handleChangeView}>
        <ListItemIcon>
          <BiotechIcon />
        </ListItemIcon>
        <ListItemText primary="Single Chip" />
      </ListItemButton>
      <ListItemButton name="AlignPage" onClick={handleChangeView}>
        <ListItemIcon>
          <FingerprintIcon />
        </ListItemIcon>
        <ListItemText primary="Align List" />
      </ListItemButton>
      <ListItemButton name="AnalysisPage" onClick={handleChangeView}>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary="Analysis List" />
      </ListItemButton>
      <ListItemButton name="FlowcellTileStatsPage" onClick={handleChangeView}>
        <ListItemIcon>
          <LayersIcon />
        </ListItemIcon>
        <ListItemText primary="Flowcell Tile Stats" />
      </ListItemButton>
      <ListItemButton name="FastqViewPage" onClick={handleChangeView}>
        <ListItemIcon>
          <SubtitlesIcon />
        </ListItemIcon>
        <ListItemText primary="FASTQ" />
      </ListItemButton>
    </React.Fragment>
  )
}

export default MenuList;