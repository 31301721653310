import { useEffect, useState } from "react";
import { useUser } from "../../UserContext";
import { getIdToken } from "../Utils/AuthUtils.js";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorSnackbar from "../Utils/ErrorSnackbar.js";
import '../../App.css';

const FlowcellTileStatsList = () => {
  
  const { user } = useUser();
  const [flowcellTileStats, setFlowcellTileStats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // table column definition
  const columns = [
    { field: 'flowcell_id', headerName: 'Flowcell', width: 150 },
    { field: 'chip_id', headerName: 'Chip', width: 150 },
    { field: 'n_barcodes', headerName: 'Barcodes', width: 150 },
    { field: 'x_max_nm', headerName: 'x_max_nm', width: 150 },
    { field: 'x_min_nm', headerName: 'x_min_nm', width: 150 },
    { field: 'y_max_nm', headerName: 'y_max_nm', width: 150 },
    { field: 'y_min_nm', headerName: 'y_min_nm', width: 150 },
  ];

  // get analysis list from API
  const getFlowcellTileStatsList = async () => {
    try {
      setLoading(true);
      const idToken = await getIdToken();
      const url = process.env.REACT_APP_GET_FLOWCELL_TILE_STATS_ENDPOINT;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${idToken}`
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setFlowcellTileStats(data);
      setError(null);
    } catch (e) {
      console.error("Error occurred when fetching data from API.",e.message);
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }

  // fetch list for once
  useEffect(() => {
    user && getFlowcellTileStatsList();
  }, [user]);

  useEffect(() => {
    error && setSnackbarOpen(true);
  }, [error]);

  return (
    <div className="sub-page-table-container">
      <ErrorSnackbar error={error} snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen} />
      {loading ?
        <CircularProgress /> :
        <DataGrid
          rows={flowcellTileStats}
          columns={columns}
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10, 50]}
        />
      }
    </div>
  )
}

export default FlowcellTileStatsList;