import React from 'react';

function NotFoundPage() {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>404</h1>
      <h2>Page Not Found</h2>
      <p>The page you are looking for does not exist or another error occurred.</p>
      <p>Go back, or head over to the home page to choose a new direction.</p>
    </div>
  );
}

export default NotFoundPage;