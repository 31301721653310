import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { getIdToken } from "./Utils/AuthUtils.js";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useEffect } from 'react';
import { Button } from '@mui/material';

const LandingPage = ({ setTitle }) => {


  useEffect(() => {
    setTitle("Dashboard");
  }, []);

  const handleTest = async () => {
    try {
      const idToken = await getIdToken();
      const flowcell_id = "N3-HG5MC";
      const chip_id = "T02A";
      const url = `https://6o8fu7qzcd.execute-api.us-east-1.amazonaws.com/dev/api/assets/${flowcell_id}/${chip_id}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${idToken}`
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log("data",data)
    } catch (e) {
      console.error("Error occurred when fetching data from API.", e.message);
    }
  }

  return (
    <div>
      <Toolbar />
      <Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}>
        <Grid container spacing={3}>
          {/* content 1 */}
          <Grid item xs={12} md={6} lg={6}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 240,
              }}
            >
              <p>Overview Datasets</p>
              <Button onClick={handleTest}>Test</Button>
            </Paper>
          </Grid>
          {/* content 2 */}
          <Grid item xs={12} md={6} lg={6}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 240,
              }}
            >
              <p>Recent Updates</p>
            </Paper>
          </Grid>
          {/* content 3 */}
          <Grid item xs={12}>
            <Paper sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 240
            }}>
              <p>Search</p>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default LandingPage;