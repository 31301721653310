import { useEffect } from "react";
import AlignList from "./AlignPage/AlignList";

const AlignPage = ({ setTitle }) => {

  useEffect(() => {
    setTitle("Align List");
  }, []);

  return (
    <AlignList />
  )
}

export default AlignPage;