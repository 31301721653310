import { useEffect } from "react";
import FastqViewList from "./FastqViewPage/FastqViewList";

const FastqViewPage = ({ setTitle }) => {

  useEffect(() => {
    setTitle("FASTQ");
  }, []);

  return (
    <FastqViewList />
  )
}

export default FastqViewPage;