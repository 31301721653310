import { BrowserRouter } from 'react-router-dom';
import Dashboard from './Components/Dashboard';
import '@fontsource/roboto/300.css';

function App() {
  return (
    <BrowserRouter>
      <Dashboard />
    </BrowserRouter>
  );
}

export default App;
