import { useEffect, useState } from "react";
import { useUser } from "../../UserContext";
import { getIdToken } from "../Utils/AuthUtils.js";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Drawer } from "@mui/material";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorSnackbar from "../Utils/ErrorSnackbar.js";
import '../../App.css';

const AlignList = () => {

  const { user } = useUser();
  const [alignList, setAlignList] = useState([]);
  const [alignInfo, setAlignInfo] = useState(null);
  const [InfoDrawerOpen, setInfoDrawerOpen] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // table column definition
  const columns = [
    { field: 'align_id', headerName: 'Align Id', width: 250 },
    { field: 'flowcell_id', headerName: 'Flowcell', width: 150 },
    { field: 'chip_id', headerName: 'Chip', width: 80 },
    { field: 'flowcellchip_id', headerName: 'Flowcell Chip Id', width: 130 },
    { field: 'species', headerName: 'Species', width: 120 },
    { field: 'description', headerName: 'Description', width: 350 },
    { field: 'histology_path', headerName: 'Histology Path', width: 250 },
    { field: 'tissue_type', headerName: 'Tissue Type', width: 100 },
    { field: 'condition', headerName: 'Condition', width: 100 },
    { field: 'prepared_by', headerName: 'Prepared By', width: 100 },
    { field: 'produced_by', headerName: 'Produced By', width: 100 }
  ];

  // get align list from API
  const getAlignList = async () => {
    try {
      setLoadingList(true);
      const idToken = await getIdToken();
      const url = process.env.REACT_APP_GET_ALIGN_LIST_ENDPOINT;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${idToken}`
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setAlignList(data);
      setError(null);
    } catch (e) {
      console.error("Error occurred when fetching data from API.",e.message);
      setError(e.message);
    } finally {
      setLoadingList(false);
    }
  }

  // get align info from API
  const getAlignInfo = async (alignId) => {
    try {
      setLoadingInfo(true);
      const idToken = await getIdToken();
      const url = process.env.REACT_APP_GET_ALIGN_INFO_ENDPOINT + alignId;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${idToken}`
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setAlignInfo(data);
      setError(null);
    } catch (e) {
      console.error("Error occurred when fetching data from API.",e.message);
      setError(e.message);
    } finally {
      setLoadingInfo(false);
    }
  }

  // fetch align info with align_id
  const handleAlignItemClick = (params, event) => {
    getAlignInfo(params.id);
    setInfoDrawerOpen(true);
  }

  // set drawer open or close, set alignInfo to null when closed
  const handleDrawerOpenClose = (newOpen) => () => {
    setInfoDrawerOpen(newOpen);
    if (!newOpen) {
      setAlignInfo(null);
    }
  }

  // fetch list for once
  useEffect(() => {
    user && getAlignList();
  }, [user]);

  useEffect(() => {
    error && setSnackbarOpen(true);
  }, [error]);

  return (
    <div className="sub-page-table-container">
      <ErrorSnackbar error={error} snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen} />
      {loadingList ? <CircularProgress /> :
        <DataGrid
          rows={alignList}
          getRowId={(row) => row.align_id}
          columns={columns}
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10, 50]}
          onRowClick={handleAlignItemClick}
          disable
        />
      }
      <Drawer
        anchor="right"
        open={InfoDrawerOpen}
        onClose={handleDrawerOpenClose(false)}
      >
        <Box
          className={`drawer-content-container ${loadingInfo ? 'loading' : ''}`}
          role="presentation"
          onClick={handleDrawerOpenClose(false)}
        >
          <Typography component="pre" style={{ fontFamily: 'monospace' }}>
            {loadingInfo ? <CircularProgress /> : JSON.stringify(alignInfo, null, 2)}
          </Typography>
        </Box>
      </Drawer>
    </div>
  )
}

export default AlignList;