import { useEffect, useState } from 'react';
import { useUser } from "../../UserContext";
import { getIdToken } from "../Utils/AuthUtils.js";
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorSnackbar from "../Utils/ErrorSnackbar.js";
import CustomDataGridTookbar from '../Utils/CustomDataGridTookbar.js';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import '../../App.css';
import FlowcellTileStats from './FlowcellTileStats.js';

const FlowcellView = ({ flowcellId, setFlowcellId }) => {

  const { user } = useUser();
  const [flowcellIds, setFlowcellIds] = useState([]);
  const [flowcellStats, setFlowcellStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);


  function extractUniqueFlowcellIds(data) {
    const uniqueFlowcellIds = data.reduce((acc, item) => {
      if (!acc.seen[item.flowcell_id]) {
        acc.seen[item.flowcell_id] = true;
        acc.result.push(item.flowcell_id);
      }
      return acc;
    }, { seen: {}, result: [] }).result;
    return uniqueFlowcellIds;
  }

  const getFlowcellChips = async () => {
    try {
      setLoading(true);
      const idToken = await getIdToken();
      const url = process.env.REACT_APP_GET_FLOWCELL_LIST_ENDPOINT;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${idToken}`
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      const uniqueFlowcellIds = extractUniqueFlowcellIds(data);
      setFlowcellIds(uniqueFlowcellIds);
      setError(null);
    } catch (e) {
      console.error("Error occurred when fetching data from API.", e.message);
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }

  const getFlowcellStats = async () => {
    try {
      setLoading(true);
      const idToken = await getIdToken();
      const url = process.env.REACT_APP_GET_FLOWCELL_STATS_ENDPOINT + flowcellId;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${idToken}`
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      // console.log("data:",data)
      setFlowcellStats(data);
      setError(null);
    } catch (e) {
      console.error("Error occurred when fetching data from API.", e.message);
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    user && getFlowcellChips();
  }, [user]);

  useEffect(() => {
    // flowcellId && getFlowcellStats();
  }, [flowcellId]);

  useEffect(() => {
    error && setSnackbarOpen(true);
  }, [error]);

  return (
    <div className='sub-column-table-container'>
      <ErrorSnackbar error={error} snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen} />
      {loading ? <CircularProgress /> :
        <Box sx={{ width: '100%' }}>
          <FormControl sx={{ width: '300px' }}>
            <Autocomplete
              options={flowcellIds || []}
              getOptionLabel={(option) => option ? option : ''}
              id="controlled-flowcell"
              value={flowcellId}
              onChange={(event, newValue) => { setFlowcellId(newValue); }}
              renderInput={(params) => (
                <TextField {...params} label="Select Flowcell" variant="standard" />
              )}
            />
          </FormControl>
          {/* <FlowcellTileStats flowcellStats={flowcellStats} /> */}
          
        </Box>
      }
    </div>
  )
}

export default FlowcellView;