import { useEffect, useState } from 'react';
import { useUser } from "../UserContext";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import FlowcellView from './SingleChipPage/FLowcellView';
import ChipView from './SingleChipPage/ChipView';
import '../App.css';

const SingleChipPage = ({ setTitle }) => {

  const { user } = useUser();
  const [flowcellId, setFlowcellId] = useState(null);
  const [chipId, setChipId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setTitle("Single Chip");
  }, []);

  return (
    <div className='single-chip-container'>
      <Box height="100%">
        <Grid container margin={0} spacing={1} style={{ height: '100%' }}>
          <Grid item xs={12} md={6} lg={3} style={{ height: '100%' }}>
            <Paper elevation={2} className='single-chip-view-grid'>
              <Typography variant="h6">
                FLOWCELL
              </Typography>
              <FlowcellView flowcellId={flowcellId} setFlowcellId={setFlowcellId} />
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Paper elevation={2} className='single-chip-view-grid'>
              <Typography variant="h6">
                CHIP
              </Typography>
              <ChipView flowcellId={flowcellId} chipId={chipId} setChipId={setChipId} />
            </Paper>
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <Paper elevation={2} className='single-chip-view-grid'>
              <Typography variant="h6">
                ALIGN | ANALYSIS
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

export default SingleChipPage;