import Auth from './Auth';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import '../App.css';

const AppBar = ({ drawerOpen, setDrawerOpen, title }) => {

  const theme = useTheme();

  const handleDrawerOpen = () => {
    setDrawerOpen(!drawerOpen);
  }

  return (
    <MuiAppBar
      open={drawerOpen}
      className={`appbar ${drawerOpen ? 'open' : ''}`}
      sx={{ zIndex: theme.zIndex.drawer + 1, }}>
      <div className='appbar-content'>
        <Toolbar className='toolbar'>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(drawerOpen && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {title}
          </Typography>
        </Toolbar>
        <Auth />
      </div>
    </MuiAppBar>
  )
}

export default AppBar;