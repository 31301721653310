import { useEffect, useState } from "react";
import { useUser } from "../../UserContext";
import { getIdToken } from "../Utils/AuthUtils.js";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorSnackbar from "../Utils/ErrorSnackbar.js";
import '../../App.css';
import ChipQcInfo from "./ChipQcInfo.js";

const ChipView = ({ flowcellId, chipId, setChipId }) => {

  const { user } = useUser();
  const [chipIds, setChipIds] = useState([]);
  const [qcInfo, setQcInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const extractUniqueChipIds = (data) => {
    const uniqueChipIds = data.reduce((acc, item) => {
      if (!acc.seen[item.chip_id]) {
        acc.seen[item.chip_id] = true;
        acc.result.push(item.chip_id);
      }
      return acc;
    }, { seen: {}, result: [] }).result;
    return uniqueChipIds;
  }

  const transformKeys = obj =>
    Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [
        key.replace(/[\s/]/g, '_').replace(/[()]/g, ''),
        value
      ])
    );

  const getChipsFromFlowcellId = async () => {
    try {
      setLoading(true);
      const idToken = await getIdToken();
      const url = process.env.REACT_APP_GET_FLOWCELL_LIST_ENDPOINT + flowcellId;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${idToken}`
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      const uniqueChipIds = extractUniqueChipIds(data);
      setChipIds(uniqueChipIds);
      setError(null);
    } catch (e) {
      console.error("Error occurred when fetching data from API.", e.message);
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }

  const getQC = async () => {
    try {
      setLoading(true);
      const idToken = await getIdToken();
      const gSheetId = "1EkalosaSerlr0fsB5Kf1DhJNLgnswee9IkCPp3e7wgM";
      const sheetName = "interpretable-new"
      const url = `${process.env.REACT_APP_GET_GOOGLE_SHEET_ENDPOINT}${gSheetId}/${sheetName}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${idToken}`
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      const chipItem = data.find(item => item.Section === chipId);
      setQcInfo(transformKeys(chipItem));
      console.log(transformKeys(chipItem))
      setError(null);
    } catch (e) {
      console.error("Error occurred when fetching data from API.", e.message);
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    user && flowcellId && getChipsFromFlowcellId();
  }, [flowcellId]);

  useEffect(() => {
    chipId && getQC();
  }, [chipId]);

  useEffect(() => {
    error && setSnackbarOpen(true);
  }, [error]);

  return (
    <div className='sub-column-table-container'>
      <ErrorSnackbar error={error} snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen} />
      {loading ? <CircularProgress /> :
        <Box sx={{ width: '100%' }}>
          <FormControl sx={{ width: '300px' }}>
            <Autocomplete
              options={chipIds || []}
              getOptionLabel={(option) => option ? option : ''}
              id="controlled-flowcell"
              value={chipId}
              onChange={(event, newValue) => { setChipId(newValue); }}
              renderInput={(params) => (
                <TextField {...params} label="Select Chip" variant="standard" />
              )}
            />
          </FormControl>
          <ChipQcInfo qcInfo={qcInfo} />
        </Box>
      }
    </div>
  )
}

export default ChipView;