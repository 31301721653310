import { useEffect, useState } from "react";
import { useUser } from "../../UserContext";
import { getIdToken } from "../Utils/AuthUtils.js";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorSnackbar from "../Utils/ErrorSnackbar.js";
import '../../App.css';

const FastqViewList = () => {

  const { user } = useUser();
  const [fastqPairs, setFastqPairs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // table column definition
  const columns = [
    { field: 'fq_id', headerName: 'FASTQ Id', width: 250 },
    { field: 'fq_dir', headerName: 'FASTQ Directory', width: 450 },
    { field: 'fq1_name', headerName: 'FASTQ 1 Name', width: 350 },
    { field: 'fq2_name', headerName: 'FASTQ 2 Name', width: 350 }
  ];

  // get analysis list from API
  const getFastqViewList = async () => {
    try {
      setLoading(true);
      const idToken = await getIdToken();
      if (idToken == null) {
        throw new Error('User not authenticated.');
      }
      const url = process.env.REACT_APP_GET_FASTQ_PAIR_ENDPOINT;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${idToken}`
        },
      });
      if (!response.ok) {
        throw new Error(`Error fetching data from API! status: ${response.status}`);
      }
      const data = await response.json();
      setFastqPairs(data);
      setError(null);
    } catch (e) {
      console.error("Error occurred when fetching data from API.",e.message);
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }

  // fetch list for once
  useEffect(() => {
    user && getFastqViewList();
  }, [user]);

  useEffect(() => {
    error && setSnackbarOpen(true);
  }, [error]);

  return (
    <div className="sub-page-table-container">
      <ErrorSnackbar error={error} snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen} />
      {loading ? <CircularProgress /> :
        <DataGrid
          rows={fastqPairs}
          getRowId={(row) => row.fq_id}
          columns={columns}
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10, 50]}
        />
      }
    </div>
  )
}

export default FastqViewList;