import { fetchAuthSession } from 'aws-amplify/auth';

export const getIdToken = async () => {
  try {
    const session = await fetchAuthSession(); // Retrieves the current session
    return session.tokens.idToken.toString(); // Gets the JWT access token from the session
  } catch (error) {
    console.error('Error fetching access token:', error);
    return null;
  }
}

export const getAccessToken = async () => {
  try {
    const session = await fetchAuthSession(); // Retrieves the current session
    return session.tokens.accessToken.toString(); // Gets the JWT access token from the session
  } catch (error) {
    console.error('Error fetching access token:', error);
    return null;
  }
}