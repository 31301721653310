import * as React from 'react';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import MenuList from './MenuList';
import '../App.css';

const Drawer = ({ drawerOpen, setDrawerOpen }) => {

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  }

  return (
    <MuiDrawer
      variant="permanent"
      open={drawerOpen}
      classes={{
        paper: `mui-drawer-paper ${drawerOpen ? 'open' : 'closed'}`,
      }} >
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
        }}
      >
        <IconButton onClick={toggleDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <List component="nav">
        <MenuList />
      </List>
    </MuiDrawer>
  )
}

export default Drawer;