import { useEffect, useState } from "react";
import { Amplify } from 'aws-amplify';
import config from '../amplifyconfiguration.json';
import { Hub } from "aws-amplify/utils";
import { signInWithRedirect, signOut, getCurrentUser, fetchUserAttributes, fetchAuthSession } from 'aws-amplify/auth';
import { useUser } from "../UserContext";
import { IconButton } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import '../App.css';

Amplify.configure(config);

const Auth = () => {
  const { user, setUser } = useUser();
  const [error, setError] = useState(null);
  const [customState, setCustomState] = useState(null);

  useEffect(() => {
    const unsubscribe = Hub.listen('auth', ({ payload }) => {
      switch (payload.event) {
        case 'signInWithRedirect':
          getUser();
          break;
        case 'signInWithRedirect_failure':
          setError('An error has ocurred during the Oauth flow.');
          break;
        case 'customOAuthState':
          setCustomState(payload.data);
          break;
      }
    });
    getUser();
    return unsubscribe;
  }, []);

  const getUser = async () => {
    try {
      const currentUser = await getCurrentUser();
      const userInfo = await fetchUserAttributes();
      const session = await fetchAuthSession();
      currentUser['usergroups'] = session?.tokens?.idToken?.payload["cognito:groups"];
      currentUser['attributes'] = userInfo;
      setUser(currentUser);
    } catch (error) {
      console.log('Not signed in');
    }
  };

  function handleSignInClick() {
    signInWithRedirect({
      provider: "Google",
    });
  }

  return (
    <div className="auth">
      {user ? (
        <div>
          <span>{user.attributes.email}</span>
          <IconButton color="inherit" onClick={() => signOut()}>
            <LoginIcon />
          </IconButton>
        </div>
      ) : (
        <div>
          <span>Sign in with Google</span>
          <IconButton color="inherit" onClick={handleSignInClick} >
            <LoginIcon />
          </IconButton>
        </div>
      )}
    </div>
  )
}

export default Auth;

