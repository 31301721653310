import { useEffect } from "react";
import AnalysisList from "./AnalysisPage/AnalysisList";

const AnalysisPage = ({ setTitle }) => {

  useEffect(() => {
    setTitle("Analysis List");
  }, []);

  return (
    <AnalysisList />
  )
}

export default AnalysisPage;