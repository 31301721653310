import { useEffect } from "react";
import FlowcellTileStatsList from "./FlowcellTileStatsPage/FlowcellTileStatsList";

const FlowcellTileStatsPage = ({ setTitle }) => {

  useEffect(() => {
    setTitle("Flowcell Tile Stats");
  }, []);

  return (
    <FlowcellTileStatsList />
  )
}

export default FlowcellTileStatsPage;