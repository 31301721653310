import { useEffect, useState } from "react";
import { useUser } from "../../UserContext";
import { getIdToken } from "../Utils/AuthUtils.js";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Drawer } from "@mui/material";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorSnackbar from "../Utils/ErrorSnackbar.js";
import '../../App.css';

const AnalysisList = () => {

  const { user } = useUser();
  const [analysisList, setAnalysisList] = useState([]);
  const [analysisInfo, setAnalysisInfo] = useState(null);
  const [InfoDrawerOpen, setInfoDrawerOpen] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // table column definition
  const columns = [
    { field: 'analysis_id', headerName: 'Analysis Id', width: 350 },
    { field: 'flowcell_id', headerName: 'Flowcell', width: 150 },
    { field: 'chip_id', headerName: 'Chip', width: 150 },
    { field: 'align_id', headerName: 'Align Id', width: 350 },
    { field: 'analysis_type', headerName: 'Analysis Type', width: 150 }
  ];

  // get analysis list from API
  const getAnalysisList = async () => {
    try {
      setLoadingList(true);
      const idToken = await getIdToken();
      const url = process.env.REACT_APP_GET_ANALYSIS_LIST_ENDPOINT;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${idToken}`
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setAnalysisList(data);
      setError(null);
    } catch (e) {
      console.error("Error occurred when fetching data from API.",e.message);
      setError(e.message);
    } finally {
      setLoadingList(false);
    }
  }

  // get align info from API
  const getAnalysisInfo = async (analysisId) => {
    try {
      setInfoDrawerOpen(true);
      setLoadingInfo(true);
      const idToken = await getIdToken();
      const url = process.env.REACT_APP_GET_ANALYSIS_INFO_ENDPOINT + analysisId;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${idToken}`
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setAnalysisInfo(data);
      setError(null);
    } catch (e) {
      console.error("Error occurred when fetching data from API.",e.message);
      setError(e.message);
    } finally {
      setLoadingInfo(false);
    }
  }

  // fetch align info with align_id
  const handleAnalysisItemClick = (params, event) => {
    getAnalysisInfo(params.id);
    setInfoDrawerOpen(true);
  }

  // set drawer open or close, set analysisInfo to null when closed
  const handleDrawerOpenClose = (newOpen) => () => {
    setInfoDrawerOpen(newOpen);
    if (!newOpen) {
      setAnalysisInfo(null);
    }
  }

  // fetch list for once
  useEffect(() => {
    user && getAnalysisList();
  }, [user]);

  useEffect(() => {
    error && setSnackbarOpen(true);
  }, [error]);

  return (
    <div className="sub-page-table-container">
      <ErrorSnackbar error={error} snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen} />
      {loadingList ? <CircularProgress /> :
        <DataGrid
          rows={analysisList}
          getRowId={(row) => row.analysis_id}
          columns={columns}
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10, 50]}
          onRowClick={handleAnalysisItemClick}
        />
      }
      <Drawer
        anchor="right"
        open={InfoDrawerOpen}
        onClose={handleDrawerOpenClose(false)}
      >
        <Box
          className={`drawer-content-container ${loadingInfo ? 'loading' : ''}`}
          role="presentation"
          onClick={handleDrawerOpenClose(false)}
        >
          <Typography component="pre" style={{ fontFamily: 'monospace' }}>
            {loadingInfo ? <CircularProgress /> : JSON.stringify(analysisInfo, null, 2)}
          </Typography>
        </Box>
      </Drawer>
    </div>
  )
}

export default AnalysisList;