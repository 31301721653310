import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const ChipQcInfo = ({ qcInfo }) => {
  return (
    <Card variant="outlined" sx={{ marginTop: 1 }}>
      <CardContent>
        <Typography variant="h5" component="div">
          QC Info
        </Typography>
        <Stack direction="column" spacing={0.5}>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Typography color="text.secondary">
              Area (mm2):
            </Typography>
            <Typography color="text.textPrimary">
              {qcInfo?.Area_mm2}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Typography color="text.secondary">
              Barcodes:
            </Typography>
            <Typography color="text.textPrimary">
              {qcInfo?.Barcodes}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Typography color="text.secondary">
              Barcode um2:
            </Typography>
            <Typography color="text.textPrimary">
              {qcInfo?.Barcode_um2}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Typography color="text.secondary">
              NRead:
            </Typography>
            <Typography color="text.textPrimary">
              {qcInfo?.NRead}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Typography color="text.secondary">
              NMatch:
            </Typography>
            <Typography color="text.textPrimary">
              {qcInfo?.NMatch}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Typography color="text.secondary">
              Suffix:
            </Typography>
            <Typography color="text.textPrimary">
              {qcInfo?.Suffix}
            </Typography>
          </Box>
        </Stack>

      </CardContent>
      <CardActions>
        <Button size="small">Original Google Sheet</Button>
      </CardActions>
    </Card>
  )
}

export default ChipQcInfo;