import { Route, Routes } from 'react-router-dom';
import { useState } from "react";
import AppBar from "./AppBar";
import Drawer from "./Drawer";
import SingleChipPage from './SingleChipPage';
import AlignPage from './AlignPage';
import AnalysisPage from './AnalysisPage';
import FlowcellTileStatsPage from './FlowcellTileStatsPage';
import FastqViewPage from './FastqViewPage';
import NotFoundPage from './NotFoundPage';
import Box from '@mui/material/Box';
import '../App.css';
import DefaultView from './LandingPage';

const Dashboard = () => {

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [title, setTitle] = useState("Dashboard");

  return (
    <Box className="root-container">
      <AppBar drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} title={title} />
      <Drawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
      <div className={`dashboard-box-container ${drawerOpen ? 'open' : 'closed'}`}>
        <Routes>
          <Route path="/" element={<DefaultView setTitle={setTitle} />} />
          <Route path="/SingleChipPage" element={<SingleChipPage setTitle={setTitle} />} />
          <Route path="/AlignPage" element={<AlignPage setTitle={setTitle} />} />
          <Route path="/AnalysisPage" element={<AnalysisPage setTitle={setTitle} />} />
          <Route path="/FlowcellTileStatsPage" element={<FlowcellTileStatsPage setTitle={setTitle} />} />
          <Route path="/FastqViewPage" element={<FastqViewPage setTitle={setTitle} />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
    </Box >
  )
}

export default Dashboard;